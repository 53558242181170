import React, { createContext, useState } from "react";

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
    const [language, setLanguage] = useState(null);
    const [isChangeLang, setIsChangeLang] = useState(false);

    const ChangeLanguage = (language_id) => {
        setLanguage(language_id);
        setIsChangeLang(true);
    };

    return (
        <SessionContext.Provider value={{ language, isChangeLang, ChangeLanguage }}>
            {children}
        </SessionContext.Provider>
    );
};

export default SessionContext;
