export default {
    us_es:"Nosotros",
    us_en:"About us",
    serv_es_text:"Servicos",
    serv_en_text:"Services",
    search_es:"Buscar",
    search_en:"SEARCH",
    dev_es_text:"DESAROLLO DE SOFTWARE",
    dev_en_text:"SOFTWARE DEVELOPMENT",
    dev_app_es_text:"DESAROLLO DE APP'S",
    dev_app_en_text:"APP DEVELOPMENT",
    pages_web_es:"Paginas web",
    pages_web_en:"Web app's",
    aces_es:"Acesoramiento",
    aces_en:"Assist 24h",
    app_develop_es:"Desarrollo de App's",
    app_develop_en:"App development",
    custom_dev_es:"Software a la medida",
    custom_dev_en:"Custom Software",
    bigdata_es:"Big data & IA",
    bigdata_en:"DATA SCIENCE & AI"

}