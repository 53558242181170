import React, { useState, useContext } from "react";
import icons from "../imgpaths/icons_path"
import namesBrand from "../texts/brand/names"
import traslate_serv from "../texts/services/servicesText"
import SessionContext from "../contexts/Session_context";

const Navbar_Main = () => {
  const { language, isChangeLang, ChangeLanguage } = useContext(SessionContext);
  const handleLanguageChange = () => {
    // Cambia el idioma según tu lógica; aquí asumo que '1' es el idioma en inglés
    ChangeLanguage(language === 0 ? 1 : 0); // Ejemplo: alterna entre 1 y 2
  };
  return (


    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="#">{namesBrand.brandName}</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">


          <li className="nav-item dropdown">
            {
              language === 1 ?
                <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                  {traslate_serv.serv_es_text}
                </a>
                :
                <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                  {traslate_serv.serv_en_text}
                </a>
            }
            {
              language === 1 ? 
              <div className="dropdown-menu">
              <a className="dropdown-item" href="#">{traslate_serv.pages_web_es}</a>
              <a className="dropdown-item" href="#">{traslate_serv.app_develop_es}</a>
              <a className="dropdown-item" href="#">{traslate_serv.bigdata_es}</a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#">{traslate_serv.custom_dev_es}</a>
            </div>

            :
            <div className="dropdown-menu">
            <a className="dropdown-item" href="#">{traslate_serv.pages_web_en}</a>
            <a className="dropdown-item" href="#">{traslate_serv.app_develop_en}</a>
            <a className="dropdown-item" href="#">{traslate_serv.bigdata_en}</a>

            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#">{traslate_serv.custom_dev_en}</a>
          </div>
            }
          
          </li>


       
          <li className="nav-item">
            {
              language=== 1 ? 
              <a className="nav-link" href="#">{traslate_serv.aces_es}</a>
              :
              <a className="nav-link" href="#">{traslate_serv.aces_en}</a>
            }
          </li>



          <li className="nav-item active">
            {
              language === 1 ?

                <a className="nav-link" href="#">{traslate_serv.us_es}<span className="sr-only">(current)</span></a>
                :
                <a className="nav-link" href="#">{traslate_serv.us_en}<span className="sr-only">(current)</span></a>


            }

          </li>




        </ul>
        <form className="form-inline my-2 my-lg-0">{

          language === 1 ?
            <input className="form-control mr-sm-2" type="search" placeholder="Buscar" aria-label="Search" />
            :
            <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />

        }

          <button className="btn btnsearch my-2 my-sm-0" type="submit">
            {
              language === 1 ?
              
                <img className="iconsbuscar" src={icons.buscar_icon} />
                :
                <img className="iconsbuscar" src={icons.buscar_icon} />

            }
          </button>
        </form>

        <li className="nav-item active">

            <button
              type="submit"
              className="btn btnsearch"
              id="languageSwitch"
              checked={language === 1} // Si '1' es el idioma seleccionado, está marcado
              onClick={handleLanguageChange}
            >
              <label className="form-check-label" htmlFor="languageSwitch">
                {language === 1 ? 'English' : 'Español'} {/* Cambia el texto según el idioma */}
              </label>
            </button>

        </li>
      </div>
    </nav>
  );
};

export default Navbar_Main;



{/*}
    <nav className="nav nav-pills flex-row flex-sm-row ">
      <a className="flex-sm-fill text-sm-center nav-link  nav_link_txt" href="#">
        SERVICIOS 
      </a>
      <a className="flex-sm-fill text-sm-center nav-link" href="#">
        ASESORAMIENTO
      </a>
      <a className="flex-sm-fill text-sm-center nav-link" href="#">
        NOSOTROS
      </a>
      <a className="flex-sm-fill text-sm-center nav-link" href="#">
        CONTACTO
      </a>
    </nav>
    */}
