import logo from './logo.svg';
import './App.css';
import Navbar_Main from './components/navigation/NavBarHome';
import Carrousell from './components/views/CarruselHome';
import CardServices from './components/views/CardsHome';
import brantxt from  "./components/texts/brand/names"
import { SessionProvider } from './components/contexts/Session_context';
import CardAppDev from './components/views/CardApps';
import CardBD_AI from './components/views/CardBigData';

const Slogan='MAKE IT CODE <> MAKE IT REAL'

function App() {
  return (

    <SessionProvider>
    <div className="App parallax_background">
      <Navbar_Main />

       <CardServices/>

       <CardAppDev/>
       <CardBD_AI />


  
     
       
      
 
    </div>

    </SessionProvider>
  );
}

export default App;
