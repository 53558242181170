import React, { useContext, useState } from "react";
import imgcard1 from "../../assets/img/ss.jpg";
import imgcard2 from "../../assets/img/ss.jpg"; // Importa otra imagen para el segundo servicio
import imgs from "../imgpaths/icons_path"
import text_bbook from "../texts/services/servicesText"
import descrptions from "../texts/services/descriptions_services"
import SessionContext from "../contexts/Session_context";


const CardServices = () => {
    const { language, isChangeLang, ChangeLanguage } = useContext(SessionContext);
    console.log(language)



    return (
        <div className="CardHM">
            <div className="container ">
                {
                    language === 1 ?
                        <div className="row justify-content-center align-items-center cards_home">
                            <div className="col-md-6">
                                <h1 className="txtTitle_Desc_serv">{text_bbook.dev_es_text}</h1>
                                <h5 className="txtDesc_serv">{descrptions.dev_soft_description}</h5>
                     
                                <img className="iconWdev" src={imgs.idea_icon} />
                                <h5 className="txtDesc_serv">{descrptions.dev_soft_descriptionbr}</h5>
                                <img className="iconWdev" src={imgs.self_manag_icon} />
                            </div>
                            <div className="col-md-6 text-end">

                            <h3 className="txtDesc_serv">{descrptions.automat_title_es}</h3>
                            <h5 className="txtDesc_serv">{descrptions.automat_es}</h5>
                            <img className="iconWdev" src={imgs.soft1} />


                            </div>
                        </div>
                        :
                        <div className="row justify-content-center align-items-center cards_home">
                            <div className="col-md-6">

                                <h1 className="txtTitle_Desc_serv">{text_bbook.dev_en_text}</h1>
                                <h5 className="txtDesc_serv">{descrptions.dev_soft_desc_eng}</h5>
                                <h5 className="txtDesc_serv">{descrptions.dev_soft_descbr_eng}</h5>
                            </div>
                            <div className="col-md-6 text-end">
                            <h3 className="txtDesc_serv">{descrptions.automat_title_en}</h3>
                            <h5 className="txtDesc_serv">{descrptions   .automat_en}</h5>
                            <img className="iconWdev" src={imgs.soft1} />
                            </div>
                        </div>
                }
            </div>
        </div>
    );
};

export default CardServices;
